import React, { useContext, useState, useEffect, useRef } from "react";
import Skills from "../skills/Skills";
import SerlianContext from "../../../context";
import {
  FaAt,
  FaLinkedin,
  FaGithubSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import Conferences from "../conferences/Conference";
import Missions from "../Missions";
import { getUserMissions } from "../../../utils/fetch/fetchMissions";
import EditInformations from "./EditInformations";
import { fetchUpdateProfileInformation } from "../../../utils/fetch/fetchUser";
import Toast from "../../../components/Toast";

const Informations = (props) => {
  const { idSerlian, isEditable, updateSerlian, updateProfilPicture } = props;
  const context = useContext(SerlianContext);

  //states
  const [serlian, setSerlian] = useState(props.serlian);
  const [currentSerlian, setCurrentSerlian] = useState(props.serlian);
  const [updatedSerlian, setUpdatedSerlian] = useState(serlian);
  const [currentMission, setCurrentMission] = useState(null);
  const [picture, setPicture] = useState(null);
  const [valueButton, setValueButton] = useState("Envoyer");
  const [disabledButton, setDisabledButton] = useState(false);
  const [clazz, setClazz] = useState("form--modal");
  const [inputFileKey, setInputFileKey] = useState(Date.now());
  const [toast, setToast] = useState(null);
  const [errorPicture, setErrorPicture] = useState(false);
  const [errorMailFormat, setErrorMailFormat] = useState(false);
  const [errorMailExist, setErrorMailExist] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [linkErrors, setLinkErrors] = useState({
    blog_article: "",
    linkedin: "",
    github: "",
    twitter: ""
});


  const fileInputRef = useRef(null);

  //récupération des missions au chargement puis quand le serlien change 
  useEffect(() => {
    getUserMissions(idSerlian, (fetchedMissions) => {
      setCurrentMission(fetchedMissions[0]);
    });
  }, [idSerlian]);

//gestion de l'affichage de la modal pour modifier les informations
  const toggleModal = (event) => {
    if (event.classList.contains("toggle-add")) {
      setClazz("form--modal");
      setCurrentSerlian(serlian);
    }
  };

  //Mise à jour les informations du profil utilisateur
  const updateProfileInformation = () => {
    const formData = new FormData();
    formData.append("picture", picture);
    formData.append("serlian", JSON.stringify(updatedSerlian));

    fetchUpdateProfileInformation(formData, setIsEditing, (response) => {
      if (response.name === "success") {
        setToast("SUCCESS");
        setErrorMailExist(false);
        setInputFileKey(Date.now());

        if (!setIsEditing) {
          serlian.city = "";
          serlian.blog_article = "";
          serlian.linkedin = "";
          serlian.github = "";
          serlian.twitter = "";
          setSerlian(serlian);
          setPicture(null);
        }
        if (response.picture && response.picture !== "success") {
          setToast("ERROR_PICTURE");
        }
      } else if (response.message === "EMAIL_EXIST") {
        setErrorMailExist(true);
      } else {
        setToast("ERROR");
      }
    });
  };

  //gestion des modifications
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const file = files[0];
      const img = new Image();
      img.onload = function () {
        if (img.width !== img.height) {
          setErrorPicture(true);
          alert("L'image doit être carrée ");
          return
        }

        if (img.width > 1000 || img.height > 1000) {
          setErrorPicture(true);
          alert("L'image doit être carré et avoir une taille maximale de 1000 pixels x 1000 pixels");
          return;
        }

   setErrorPicture(false); 
   setPicture(file);
   setUpdatedSerlian((prevState) => ({
     ...prevState,
     [name]: file,
   }));
      };


      img.onerror = function () {
        alert("Fichier invalide: " + file.type);
      };
      img.src = window.URL.createObjectURL(file);

    
      setCurrentSerlian((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {

      if (value.length < context.maxLength) {
        setUpdatedSerlian((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setCurrentSerlian((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      clearTimeout(window.validateTimeout);
      window.validateTimeout = setTimeout(() => {
          if (value.length > 0 && !validateLink(name, value)) {
              setLinkErrors((prev) => ({ ...prev, [name]: `Le lien pour ${name} est invalide.` }));
          } else {
              setLinkErrors((prev) => ({ ...prev, [name]: "" }));
          }
      }, 500);

    }

    setUpdatedSerlian((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setCurrentSerlian((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //réinitialisation des champs et erreurs
  const resetField = () => {
    setPicture(null);
    setErrorPicture(false);
    setInputFileKey(Date.now());
  };

  //gestion du changement de la photo
  const handleUpdatePicture = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  //vérification des liens
  const validateLink = (name, value) => {
    switch (name) {
      case "blog_article":
        return value.startsWith("https://teamserli.blogspot.com/");
      case "linkedin":
        return value.startsWith("https://www.linkedin.com/");
      case "github":
        return value.startsWith("https://github.com/");
      case "twitter":
        return value.startsWith("https://x.com/") || value.startsWith("https://twitter.com/");
      default:
        return true; 
    }
  };

  //gestion de la soumission des changement 
  const handleSubmit = (e) => {
    e.preventDefault();


    const hasLinkErrors = Object.values(linkErrors).some(error => error !== "");
    if (hasLinkErrors) {
        setToast("ERROR_LINK"); 
        return; 
    }

    setValueButton(<div className="loader" />);
    setDisabledButton(true);
    updateProfileInformation();
    setValueButton("Envoyer");
    setDisabledButton(false);
    setTimeout(
      function () {
        setToast("");
        setClazz("form--modal");
        window.location.reload();
      }.bind(),
      3000
    );
  };

  //gestion des toast
  const renderToast = () => {
    switch (toast) {
      case "SUCCESS":
        return (
          <Toast
            type="success"
            title="Succès"
            message={"Ton profil a bien été modifié."}
          />
        );
      case "ERROR":
        return (
          <Toast
            type="error"
            title="Erreur"
            message={"Ton profil n'a pas pu être modifié. Réessayes plus tard."}
          />
        );
      case "ERROR_PICTURE":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="L'image n'a pas pu être enregistrée, contactes un(e) administrateur(trice)."
          />
        );
      case "ERROR_LINK":
        return (
          <Toast
            type="error"
            title="Erreur"
            message="Un ou plusieurs liens sont invalides"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      {renderToast()}
      {serlian && (
        <>
          <div className="container--profile section--informations section--profile">
            <div
              className={
                "section--informations__picture" +
                (context.serlianConnected.role === "admin"
                  ? " section--informations__picture--edit"
                  : "")
              }
            >
              <div className="profil-picture__container ">
                <img
                  className="profil-picture__image"
                  src={serlian.picture}
                  alt="photo"
                />
                {context.serlianConnected.role === "admin" && (
                  <div
                    className="profil-picture__image--edit"
                    onClick={handleUpdatePicture}
                  >
                    <h4>
                      <strong>Modifier l'image</strong>
                    </h4>
                  </div>
                )}
              </div>
              {context.serlianConnected.role === "admin" && (
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  hidden
                  ref={fileInputRef}
                  onChange={updateProfilPicture}
                />
              )}
            </div>
            <div className="section--informations__presentation">
              <div className="section--informations__name">
                <div className="section--informations__name__serlian">
                  <h2>{serlian.firstname}</h2>
                  <h1>{serlian.lastname}</h1>
                </div>
                <div>
                  {isEditable && (
                    <button
                      className="btn btn--round"
                      title="Modifier les informations"
                      onClick={() => {
                        setClazz("form--modal toggle-add");
                      }}
                    >
                      <FiEdit3 />
                    </button>
                  )}
                </div>
              </div>
              <div>
                <div className="section--informations__current">
                  <div>
                    <p>
                      {serlian.job.substr(0, 1).toUpperCase() +
                        serlian.job.substring(1).toLowerCase()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="section--informations__contact">
                {serlian.city && (
                  <div className="contact-line">
                    <img
                      src="/images/locationIcon.png"
                      alt=""
                      className="contact-icons"
                    />
                    <p className="city">{serlian.city}</p>
                  </div>
                )}
                <div className="contact-line">
                  <img
                    src="/images/emailIcon.png"
                    alt=""
                    className="contact-icons"
                  />
                  <p> {serlian.email}</p>
                </div>
              </div>
              <div className="section--informations__profile-links">
                {serlian.linkedin && (
                  <a href={serlian.linkedin} target="_blank">
                    <FaLinkedin />
                  </a>
                )}
                {serlian.github && (
                  <a href={serlian.github} target="_blank">
                    <FaGithubSquare />
                  </a>
                )}
                {serlian.twitter && (
                  <a href={serlian.twitter} target="_blank">
                    <FaTwitterSquare />
                  </a>
                )}
              </div>

              {serlian.blog_article && (
                <div className="section--informations__blog">
                  <a
                    href={serlian.blog_article}
                    target="_blank"
                    className="btn btn--blue"
                  >
                    Découvrir sa présentation sur le blog
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="block__missions">
            <Missions idSerlian={serlian.id} />
          </div>

          <div className="block__skills-conferences">
            <Skills
              idSerlian={serlian.id}
              updateSerlian={updateSerlian}
              isEditable={isEditable}
            />
            <Conferences idSerlian={serlian.id} isEditable={isEditable} />
          </div>

          {/* <Training
            idSerlian={serlian.id}
            training={serlian.training}
            isEditable={isEditable}
          /> */}

          {setIsEditing && (
            <EditInformations
              toggleModal={toggleModal}
              clazz={clazz}
              currentSerlian={currentSerlian}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              valueButton={valueButton}
              errorMailFormat={errorMailFormat}
              errorMailExist={errorMailExist}
              errorPicture={errorPicture}
              picture={picture}
              inputFileKey={inputFileKey}
              resetField={resetField}
           linkErrors={linkErrors}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Informations;

import React from "react";
import { PrimaryButton } from "../../Buttons";
import { TiDelete } from "react-icons/ti";

const EditInformations = ({
  currentSerlian,
  linkErrors,
  clazz,
  handleChange,
  handleSubmit,
  toggleModal,
  resetField,
  errorPicture,
  inputFileKey,
  picture,
  valueButton,
  errorMailFormat,
}) => {
  const serlianData = { ...currentSerlian };

  return (
    <form
      className={clazz}
      onClick={(e) => toggleModal(e.target)}
      onSubmit={handleSubmit}
    >
      <div className="form--modal__container  overflow-auto">
        <div className="container container--column container--centered">
          <h2>Editer les informations</h2>
        </div>
        <label className="form__control">
          <span>Agence</span>
          <select
            className="form__input"
            name="city"
            onChange={handleChange}
            value={serlianData.city || ""}
          >
            <option value="">Selectionner une option</option>
            <option value="Agence de Niort">Agence de Niort</option>
            <option value="Agence de Poitiers">Agence de Poitiers</option>
          </select>
        </label>
        <label className="form__control">
          <span>Photo {errorPicture && <em>L'image doit être carré </em>}</span>
          <input
            type="file"
            onChange={handleChange}
            key={inputFileKey}
            className={
              errorPicture ? "form__input form__input--error" : "form__input"
            }
            accept="image/jpeg, image/png"
          />
          <button
            onClick={resetField}
            type="reset"
            className={
              picture
                ? "cross-button reset-edit show"
                : "cross-button reset-edit"
            }
          >
            <TiDelete />
          </button>
        </label>
        <label className="form__control">
          <span>Lien présentation sur le blog</span>
          <input
            type="text"
            className="form__input"
            name="blog_article"
            onChange={handleChange}
            value={serlianData.blog_article || ""}
          />
          {linkErrors.blog_article && (
            <em className="error-message">{linkErrors.blog_article}</em>
          )}
        </label>
        <label className="form__control">
          <span>Lien linkedin</span>
          <input
            type="text"
            className="form__input"
            name="linkedin"
            onChange={handleChange}
            value={serlianData.linkedin || ""}
          />
          {linkErrors.linkedin && (
            <em className="error-message">{linkErrors.linkedin}</em>
          )}
        </label>
        <label className="form__control">
          <span>Lien github</span>
          <input
            type="text"
            className="form__input"
            name="github"
            onChange={handleChange}
            value={serlianData.github || ""}
          />
          {linkErrors.github && (
            <em className="error-message">{linkErrors.github}</em>
          )}
        </label>
        <label className="form__control">
          <span>Lien twitter</span>
          <input
            type="text"
            className="form__input"
            name="twitter"
            onChange={handleChange}
            value={serlianData.twitter || ""}
          />
          {linkErrors.twitter && (
            <em className="error-message">{linkErrors.twitter}</em>
          )}
        </label>
        <div className="container container--column container--centered">
          <PrimaryButton
            disabled={errorMailFormat || errorPicture}
            id="btn-confirm"
            value={valueButton}
          />
        </div>
      </div>
    </form>
  );
};

export default EditInformations;
